import axios from 'axios';
import callAPI from '../config/api';
import { AdminTypes, LoginTypes } from './data-types';

const ROOT_API = process.env.API;
const API_VERSION = 'api/v1';

export async function setLogin(data: LoginTypes) {
  const url = `${ROOT_API}/${API_VERSION}/login-admin`;

  return callAPI({
    url,
    method: 'POST',
    data,
  });
}

export async function setLoginNew(data: LoginTypes) {
  const url = `${ROOT_API}/${API_VERSION}/login-admin-new`;

  return callAPI({
    url,
    method: 'POST',
    data,
  });
}

export async function getAdmin() {
  const url = `${ROOT_API}/${API_VERSION}/admin`;

  return callAPI({
    url,
    method: 'GET',
    token: true,
  });
}

export async function createdAdmin(data: any) {
  const url = `${ROOT_API}/${API_VERSION}/admin`;

  return callAPI({
    url,
    method: 'POST',
    data,
    token: true,
  });
}

export async function editAdmin(id: string, data: any) {
  const url = `${ROOT_API}/${API_VERSION}/admin/${id}`;

  return callAPI({
    url,
    method: 'PUT',
    data,
    token: true,
  });
}

export async function deleteAdmin(id: string) {
  const url = `${ROOT_API}/${API_VERSION}/admin/${id}`;

  return callAPI({
    url,
    method: 'DELETE',
    token: true,
  });
}

export async function registerKaryawan(data: any) {
  const url = `${ROOT_API}/${API_VERSION}/karyawan`;

  return callAPI({
    url,
    method: 'POST',
    data,
  });
}

export async function getKaryawanByPhome(data: any) {
  // console.log(data);
  const url = `${ROOT_API}/${API_VERSION}/karyawan?phone=${data}`;

  return callAPI({
    url,
    method: 'GET',
    data,
  });
}

export async function checkOtpKaryawan(data: any) {
  const url = `${ROOT_API}/${API_VERSION}/karyawan-check-otp?otp=${data.otp}&userId=${data.userId}`;

  return callAPI({
    url,
    method: 'POST',
    data,
  });
}

export async function resendOtpKaryawan(data: any) {
  const url = `${ROOT_API}/${API_VERSION}/karyawan-resend-otp?`;
  console.log(url);

  return callAPI({
    url,
    method: 'POST',
    data,
  });
}
